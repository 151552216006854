import { Link, useLocalSearchParams } from 'expo-router';
import { useCallback, useState } from 'react';
import { View } from 'react-native';

import { Button, YStack, createMqStyles, tokens, useMqSelect } from '@fhs/ui';
import { NutritionalInformation } from '@fhs/ui/src/components/nutritional-information';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';

import { useMenuPicker } from '../state/menu-picker';

import { ComboGuide } from './combo-guide';
import { CustomizationGroup } from './customization-group';
import {
  ListItemGroup,
  ListItemGroupSection,
  ListItemLinkPressable,
} from './list-item-group-section';
import { PickerAspectOptionSelector } from './picker-aspect-option-selector';
import { ExtraInstructions } from './product-detail-extra-instructions';
import { ProductDetailLayout } from './product-detail-layout';
import { ProductNameAndImage } from './product-name-and-image';

type NutritionalInformationProps = {
  isShowingNutrition: boolean;
  setIsShowingNutrition: (isShowingNutrition: boolean) => void;
};

const NutritionalInformationTile = ({
  isShowingNutrition,
  setIsShowingNutrition,
}: NutritionalInformationProps) => {
  const menuPicker = useMenuPicker();

  return (
    <View>
      <ListItemGroup
        items={[
          {
            render: ({ isFirstItem, isLastItem }) => (
              <ListItemLinkPressable
                titleFontWeight="normal"
                minHeightStyle="content"
                isFirstItem={isFirstItem}
                isLastItem={isLastItem}
                title="Nutritional Information"
                onPress={() => setIsShowingNutrition(true)}
              />
            ),
          },
        ]}
      />

      <NutritionalInformation
        nutritionFacts={{
          title: menuPicker.selectedPickerAspectOption?.item?.displayName,
          image: menuPicker.selectedPickerAspectOption?.item?.image,
          // @ts-ignore
          facts: menuPicker.selectedPickerAspectOption?.item?.nutrition ?? {},
        }}
        isVisible={isShowingNutrition}
        onClose={() => setIsShowingNutrition(false)}
      />
    </View>
  );
};

type MenuDetailsProps = NutritionalInformationProps;

export function MenuItemDetails(props: MenuDetailsProps) {
  const mqStyles = useMqStyles();
  const menuPicker = useMenuPicker();
  const extraInstructionsEnabled = useFlag(LaunchDarklyFlag.ENABLE_EXTRA_INSTRUCTIONS_ON_ITEM);
  const params = useLocalSearchParams<{ slug: string }>();
  const requiresPickAspectOptionSelection = menuPicker.query.data?.pickerAspect.options.length > 1;

  const customizationGroups =
    menuPicker.selectedPickerAspectOption?.item?.customizations.displayGroups ?? [];
  const topLevelCustomizationGroups = customizationGroups.filter(
    customizationGroup => customizationGroup.displayGroup.showOnProductPage
  );

  const hasCustomizations = customizationGroups.length > 0;

  const baseCaloriesDisplay = menuPicker.selectedPickerAspectOption?.item?.baseCalories
    ? `${menuPicker.selectedPickerAspectOption?.item?.baseCalories} Cal`
    : null;

  const hasCustomizationsContent = requiresPickAspectOptionSelection || hasCustomizations;
  const asHero = useMqSelect({ $gteDesktop: true }, !requiresPickAspectOptionSelection);

  const [isComboGuideOpen, setIsComboGuideOpen] = useState(false);
  const onComboGuideClose = useCallback(() => setIsComboGuideOpen(false), []);

  return (
    <ProductDetailLayout
      nameAndImageContent={
        <ProductNameAndImage
          displayName={menuPicker.displayName}
          description={menuPicker.query.data?.description}
          variant={menuPicker.selectedPickerAspectOption?.displayName}
          cals={baseCaloriesDisplay}
          asHero={asHero}
          imageUrl={menuPicker.query.data?.image.asset.uri}
          hasCustomizationsContent={hasCustomizationsContent}
          heroNutritionalInformationTile={
            !hasCustomizationsContent && (
              <NutritionalInformationTile
                setIsShowingNutrition={props.setIsShowingNutrition}
                isShowingNutrition={props.isShowingNutrition}
              />
            )
          }
        />
      }
      customizationContent={
        hasCustomizationsContent && (
          <YStack style={mqStyles.customizationContent}>
            {requiresPickAspectOptionSelection && (
              <ListItemGroupSection heading={menuPicker.query.data?.pickerAspect?.displayName}>
                <PickerAspectOptionSelector
                  value={menuPicker.selectedPickerAspectOptionId}
                  onChange={menuPicker.setSelectedPickerAspectOptionId}
                  options={menuPicker.query.data?.pickerAspect?.options.map(opt => ({
                    value: opt.id,
                    title: opt.displayName,
                    subtitle: opt.description,
                    image: opt.image,
                    disabled: !opt.item.isAvailable,
                  }))}
                />
              </ListItemGroupSection>
            )}
            {hasCustomizations &&
              topLevelCustomizationGroups.map((customizationGroup, index) => {
                const isFirstCustomizationGroup = index === 0;

                return (
                  <CustomizationGroup
                    key={customizationGroup.displayGroup.id ?? index}
                    customizationGroup={customizationGroup}
                    footer={
                      isFirstCustomizationGroup &&
                      topLevelCustomizationGroups.length < customizationGroups.length && (
                        <Link
                          href={{
                            pathname: '/v2/menu/[slug]/customize',
                            params: {
                              slug: params.slug,
                            },
                          }}
                          asChild
                        >
                          <Button type="outline" size="md">
                            <Button.Text>Customize Ingredients</Button.Text>
                          </Button>
                        </Link>
                      )
                    }
                  />
                );
              })}

            <Button onPress={() => setIsComboGuideOpen(true)}>
              <Button.Text>Make it a combo</Button.Text>
            </Button>
            {isComboGuideOpen && <ComboGuide onClose={onComboGuideClose} />}

            {extraInstructionsEnabled && (
              <ExtraInstructions
                orderInstructions={menuPicker.instructions}
                onInstructionsChange={menuPicker.setInstructions}
              />
            )}

            <View style={mqStyles.divider} />

            <NutritionalInformationTile
              setIsShowingNutrition={props.setIsShowingNutrition}
              isShowingNutrition={props.isShowingNutrition}
            />
          </YStack>
        )
      }
    />
  );
}

const useMqStyles = createMqStyles({
  customizationContent: {
    $base: {
      paddingHorizontal: 16,
      gap: 24,
    },
  },
  divider: {
    $base: {
      height: 1,
      backgroundColor: tokens.colors.$blackOpacity04,
    },
  },
});
