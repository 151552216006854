import { router } from 'expo-router';
import React, { useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { GestureResponderEvent } from 'react-native';

import { CheckoutErrorsModal } from 'components/checkout-errors-modal';
import CreditCardFormInputs from 'components/credit-card-form-inputs';
import PaymentMethod from 'components/payment-method';
import { CurrentSelectedMethodType } from 'components/payment-method/types';
import { getCurrentSelectedMethodType } from 'components/payment-method/utils';
import { SafetechEncryption } from 'components/payments/integrations/orbital/components/encryption';
import {
  IEncryptionError as IOrbitalEncryptionError,
  IEncryptionResult as IOrbitalEncryptionResult,
} from 'components/payments/integrations/orbital/components/encryption/types';
import { EProtectEncryption } from 'components/payments/integrations/worldpay/components/encryption';
import {
  IEncryptionError as IWorldpayEncryptionError,
  IEncryptionResult as IWorldpayEncryptionResult,
} from 'components/payments/integrations/worldpay/components/encryption/types';
import StaticMap from 'components/static-map';
import WarningMessage from 'components/warning-message';
import { PaymentMethod as PaymentMethodEnum } from 'generated/graphql-gateway';
import { useGetPaymentErrors } from 'hooks/use-get-payment-errors';
import useIsSignUpAfterCart from 'hooks/use-is-sign-up-after-cart';
import useLogOnLongLoading from 'hooks/use-log-on-long-loading';
import { CartDetailsWithDayAndTimeSelector } from 'pages/cart/cart-details-with-day-and-time-selector';
// import { PaymentRoutes } from 'pages/cart/payment/order-payment/payment-routes';
import { getServiceModeHeaderText } from 'pages/cart/types';
import { LoyaltyRewardsBalance } from 'pages/loyalty/loyalty-rewards-balance';
import { useCartContext } from 'state/cart';
import { useCRMEventsContext } from 'state/crm-events';
import { selectors, useAppSelector } from 'state/global-state';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  PaymentFieldVariations,
  SupportedCardType,
  defaultPaymentFieldVariation,
  defaultSupportedCardTypes,
} from 'state/launchdarkly/variations';
import { useLoyaltyContext } from 'state/loyalty';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { getServerOrderSubtotalBeforeOfferDiscounts } from 'state/loyalty/hooks/utils/helpers';
import { OrderStatus, ServiceMode, useOrderContext } from 'state/order';
import { usePaymentContext } from 'state/payment';
import { CardType } from 'state/payment/types';
import { useStoreContext } from 'state/store';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import logger from 'utils/logger';
import { mapPaymentMethodCardTypes } from 'utils/payment/map-payment-method-card-type';
import { checkServiceModeUnavailable, getDateNowByTimezone } from 'utils/restaurant';
import { routes } from 'utils/routing';
import { getLocalizedServiceMode, isDelivery } from 'utils/service-mode';
import { CART_PAYMENT_PAGE, CART_SELECT_PAYMENT_METHOD } from 'utils/test-ids';

import { CartPageError } from '../../cart-page-error';
import { PlaceOrderButton } from '../../place-order-button';
import SectionHeading from '../../section-heading';
import DeliveryCancellationPolicy from '../delivery-cancellation-policy';
import PaymentSelection from '../payment-selection';
import PrepaidMethodReload from '../prepaid-method-reload';
import PrepaidTransferBalance from '../prepaid-transfer-balance';
import {
  DisclaimerCardOfferInvalid,
  DisclaimerCatering,
  ErrorContainer,
  ErrorMessage,
  PaymentSection,
  WarningContainer,
} from '../styled';
import { OrderPaymentProps } from '../types';
import { logCartContinueOrder, logCartPlaceOrder } from '../utils';
import { VehicleDescription } from '../vehicle-description';

import Totals from './Totals';
import {
  CartDetailsContainer,
  CtaWrapper,
  MapContainer,
  StyledFullPageScreen,
  StyledPicture,
} from './styled';
import { useCommitOrder } from './use-commit-order';
import { useOrderPayment } from './use-order-payment';
import { getNativePaymentDisclaimer } from './util';

export const Payment: React.FC<React.PropsWithChildren<OrderPaymentProps>> = ({ onPriceOrder }) => {
  const {
    cartEntries,
    serverOrder,
    serviceMode,
    shouldSkipPriceOrder,
    areDeliveryDetailsValid,
    pricedAndAvailable,
    priceOrderSuccess,
    priceOrderFailure,
    orderStatus,
  } = useCartContext();
  const newPaymentValueIdRef = useRef('');

  const { formatMessage } = useIntl();
  const isDeliveryOrder = isDelivery(serviceMode);
  const { store } = useStoreContext();
  const { restaurantImage } = store;
  const order = useOrderContext();
  const {
    addPaymentMethod,
    canUseApplePay,
    canUseGooglePay,
    checkoutPaymentMethodId,
    setCheckoutPaymentMethodId,
    storePaymentDetails,
    transformPaymentValues,
    isOrbital,
    isWorldpay,
    setEncryptionResult,
  } = usePaymentContext();

  const [isCheckoutErrorsModalOpen, setIsCheckoutErrorsModalOpen] = useState(false);
  const [hasAddPaymentMethodError, setAddPaymentMethodError] = useState(false);
  const [hasEncryptionError, setHasEncryptionError] = useState<boolean>(false);
  const [customHeaderMessageId, setCustomHeaderMessageId] = useState<string | undefined>(undefined);

  const { loyaltyUserId } = useLoyaltyUser();
  const { evaluateLoyaltyUserIncentives, isOfferValidationError, isOfferEvaluating } =
    useLoyaltyContext();
  const appliedRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);
  const appliedLoyaltyOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const isOffersLoading = useAppSelector(selectors.loyalty.selectOffersLoading);
  const orderPayment = useOrderPayment({
    serverOrder,
    onPriceOrder,
  });
  const {
    cardIsValid,
    confirmStoreOnCheckout,
    enableDelivery,
    ErrorDialog,
    errors,
    handlePaymentMethodSelected,
    isCateringOrder,
    isOfferPaymentCardValid,
    isOrderPriceLoading,
    isValidReloadPaymentMethodWithCard,
    isValidTip,
    needReloadAgain,
    offerIsValid,
    onChange,
    openServiceModeUnavailableErrorDialog,
    paymentMethods,
    paymentValues,
    placeOrderAriaLabel,
    reloadAmount,
    reloadPaymentValues,
    ServiceModeUnavailableErrorDialog,
    setReloadPaymentValues,
    setShowAddPaymentMethod,
    showAddPaymentMethod,
    showPaymentMethodSelection,
    showPrepaidTransferBalance,
    showReloadPaymentMethods,
    validateCreditCardForm,
    isAddGiftCardMethodSelected,
    setIsAddGiftCardMethodSelected,
  } = orderPayment;

  const paymentFieldVariations =
    useFlag<PaymentFieldVariations>(LaunchDarklyFlag.PAYMENT_FIELD_VARIATIONS) ||
    defaultPaymentFieldVariation;
  const enableGiftCard = useFlag(LaunchDarklyFlag.ENABLE_GIFT_CARD);
  const checkoutDeliveryPriceMinimum = useFlag(LaunchDarklyFlag.OVERRIDE_CHECKOUT_DELIVERY_MINIMUM);
  const rawSupportedCardTypes: SupportedCardType[] =
    useFlag(LaunchDarklyFlag.SUPPORTED_CARD_BRANDS_VARIATIONS) || defaultSupportedCardTypes;
  // enable the distinct vault step on checkout for orbital.
  // for more info see: https://rbictg.atlassian.net/browse/GST-7741
  const enableOnetimePaymentAfterVault =
    useFlag(LaunchDarklyFlag.ENABLE_VAULT_AFTER_MAKING_ONE_TIME_PAYMENT) ?? true;
  const enableFutureOrdering = useFlag(LaunchDarklyFlag.ENABLE_FUTURE_ORDERING);

  const supportedCardTypes = rawSupportedCardTypes
    .map(cardType => mapPaymentMethodCardTypes(cardType))
    .filter(Boolean) as CardType[];

  const sectionRef = useRef<HTMLElement | null | undefined>();

  const nativePaymentDisclaimer = getNativePaymentDisclaimer(
    canUseApplePay,
    canUseGooglePay,
    formatMessage
  );
  const isSignUpAfterCart = useIsSignUpAfterCart();
  const checkoutPriceMinimum = isDeliveryOrder ? checkoutDeliveryPriceMinimum : 0;

  const cartSubTotal =
    isSignUpAfterCart || shouldSkipPriceOrder
      ? order.calculateCartTotal(cartEntries)
      : serverOrder?.cart?.subTotalCents;
  const cartPriceTooLow = cartSubTotal < checkoutPriceMinimum;

  const { getErrorMessages, getLoyaltyPaymentErrorMessages } = useGetPaymentErrors({
    cartPriceTooLow,
  });

  const enableRequireAddress2 = useFlag(
    LaunchDarklyFlag.ENABLE_DELIVERY_DETAILS_FORM_REQUIRE_ADDRESS_2
  );
  const initialValue = LocalStorage.getItem(StorageKeys.VEHICLE_DESCRIPTION);
  const isCurbside = serviceMode === ServiceMode.CURBSIDE;

  const [
    { value: vehicleDescription, hasError: vehicleDescriptionHasError },
    setVehicleDescription,
  ] = useState({
    value: initialValue,
    hasError: false,
  });

  const handleServiceModeErrors = () => {
    if (isCurbside) {
      if (!vehicleDescriptionHasError && vehicleDescription) {
        LocalStorage.setItem(StorageKeys.VEHICLE_DESCRIPTION, vehicleDescription);
      } else {
        return focusElementWithError('vehicleDescription');
      }
    }
  };

  const focusElementWithError = (name: string) => {
    // TODO: Remove queryselector and replace with ref's
    const element: HTMLElement | null | undefined = sectionRef.current?.querySelector(
      `[name=${name}]`
    );

    if (element) {
      element.focus();
    }
  };

  const paymentErrorMessages = getErrorMessages({
    invalidOfferError: !offerIsValid,
    invalidPaymentMethodError:
      !cardIsValid ||
      !isValidReloadPaymentMethodWithCard ||
      !isOfferPaymentCardValid ||
      hasAddPaymentMethodError,
    encryptionFail: hasEncryptionError,
    invalidTipAmountError: !isValidTip,
    pricedAndAvailableError: !pricedAndAvailable,
    areDeliveryDetailsWithError: isDeliveryOrder && !areDeliveryDetailsValid,
    invalidAddressLine2:
      enableRequireAddress2 && isDeliveryOrder && !order?.deliveryAddress?.addressLine2,
    curbsideOrderError: isCurbside && (vehicleDescriptionHasError || !vehicleDescription),
  });

  const { commitOrder, ErrorDialog: CommitErrorDialog, isCommiting } = useCommitOrder(orderPayment);

  const [isEncryptionActive, setIsEncryptionActive] = useState(false);

  const handleAddPaymentMethod = useCallback(
    async (encryptionResult?: IOrbitalEncryptionResult | IWorldpayEncryptionResult) => {
      try {
        if (isOrbital && !encryptionResult) {
          throw new Error('Orbital encryption results not available');
        }
        if (isWorldpay && !encryptionResult) {
          throw new Error('Worldpay encryption results not available');
        }

        const newPaymentValueId = await addPaymentMethod(
          transformPaymentValues({
            paymentValues,
            paymentFieldVariations,
          }),
          {
            skipErrorDialogOnError: true,
          },
          encryptionResult
        );
        setCheckoutPaymentMethodId(newPaymentValueId);
        handlePaymentMethodSelected(newPaymentValueId);
        return newPaymentValueId;
      } catch (error) {
        setAddPaymentMethodError(true);
        setIsCheckoutErrorsModalOpen(true);
        return null;
      }
    },
    [
      addPaymentMethod,
      handlePaymentMethodSelected,
      isOrbital,
      isWorldpay,
      paymentFieldVariations,
      paymentValues,
      setCheckoutPaymentMethodId,
      transformPaymentValues,
    ]
  );

  const loyaltyPaymentMethodErrors = getLoyaltyPaymentErrorMessages();
  const errorMessages = [...paymentErrorMessages, ...loyaltyPaymentMethodErrors];

  const { isCartTotalNegative = false } = order?.calculateCartTotalWithDiscount?.() || {};
  const positiveCartTotal = isCartTotalNegative
    ? 0
    : getServerOrderSubtotalBeforeOfferDiscounts(serverOrder);

  const { logRBIEvent } = useCRMEventsContext();

  const handleOnSubmit = useCallback(
    async (
      event?: GestureResponderEvent,
      encryptionResult?: IOrbitalEncryptionResult | IWorldpayEncryptionResult
    ) => {
      if (confirmStoreOnCheckout && !isDeliveryOrder) {
        logCartContinueOrder(logRBIEvent);
      }
      if (event) {
        event.preventDefault();
      }

      if (showAddPaymentMethod && !validateCreditCardForm()) {
        return;
      }

      if (
        showAddPaymentMethod &&
        validateCreditCardForm() &&
        !checkoutPaymentMethodId &&
        paymentValues.saveCard
      ) {
        const skipVaultStep = enableOnetimePaymentAfterVault && isOrbital;
        if (!skipVaultStep) {
          const paymentId = await handleAddPaymentMethod(encryptionResult);

          if (!paymentId) {
            return;
          }

          newPaymentValueIdRef.current = paymentId;
        }
      }
      if (loyaltyUserId && showAddPaymentMethod && appliedLoyaltyOffers.length) {
        const paymentMethod = PaymentMethodEnum[paymentValues.cardType];

        await evaluateLoyaltyUserIncentives({
          loyaltyId: loyaltyUserId,
          appliedLoyaltyOffers,
          cartEntries,
          appliedRewards,
          subtotalAmount: positiveCartTotal,
          paymentMethod,
        });
      }

      const dateNow = getDateNowByTimezone({
        timeZone: store.timezone || undefined,
      });

      if (
        !isCateringOrder &&
        !enableFutureOrdering &&
        checkServiceModeUnavailable(serviceMode, store, dateNow)
      ) {
        openServiceModeUnavailableErrorDialog({
          message: formatMessage(
            { id: 'currentServiceModeUnavailable' },
            {
              serviceMode: getLocalizedServiceMode(serviceMode, formatMessage),
            }
          ),
        });
        return;
      }
      // If it is a one time payment, we want to keep the info to place the order
      if (!paymentValues.saveCard) {
        storePaymentDetails({ state: paymentValues });
      }

      if (errorMessages.length > 0) {
        setIsCheckoutErrorsModalOpen(true);
      } else if (!isDeliveryOrder && confirmStoreOnCheckout) {
        router.navigate(routes.cartStoreConfirmation);
      } else {
        logCartPlaceOrder(logRBIEvent);
        commitOrder();
      }
    },
    [
      confirmStoreOnCheckout,
      isDeliveryOrder,
      showAddPaymentMethod,
      validateCreditCardForm,
      checkoutPaymentMethodId,
      paymentValues,
      loyaltyUserId,
      appliedLoyaltyOffers,
      isCateringOrder,
      enableFutureOrdering,
      serviceMode,
      store,
      errorMessages.length,
      logRBIEvent,
      enableOnetimePaymentAfterVault,
      isOrbital,
      handleAddPaymentMethod,
      evaluateLoyaltyUserIncentives,
      cartEntries,
      appliedRewards,
      positiveCartTotal,
      openServiceModeUnavailableErrorDialog,
      formatMessage,
      storePaymentDetails,
      commitOrder,
    ]
  );

  const selectedMethod = paymentMethods.find(
    method => method.accountIdentifier === checkoutPaymentMethodId
  );

  const currentSelectedMethodType = getCurrentSelectedMethodType({
    isAddGiftCardMethodSelected,
    isAddNewCardMethodSelected: showAddPaymentMethod,
    selectedMethodExist: !!selectedMethod,
  });
  const isAddNewPaymentMethodTypeSelected =
    currentSelectedMethodType === CurrentSelectedMethodType.ADD_NEW_PAYMENT_METHOD;

  const isPlaceOrderButtonDisabled =
    isOfferValidationError ||
    !pricedAndAvailable ||
    isOrderPriceLoading ||
    isCommiting ||
    (!!selectedMethod?.prepaid && !enableGiftCard) ||
    isAddNewPaymentMethodTypeSelected ||
    isEncryptionActive ||
    Boolean(order?.pickUpTipsInputError);

  const isPriceFinished = priceOrderSuccess || priceOrderFailure;

  const isPlaceOrderButtonLoading =
    !isPriceFinished ||
    isOffersLoading ||
    isCommiting ||
    isEncryptionActive ||
    isOfferEvaluating ||
    isOrderPriceLoading;

  const handleOnPress = useCallback(() => {
    handleServiceModeErrors();

    if (showAddPaymentMethod && !validateCreditCardForm()) {
      return;
    }

    if (isOrbital && !selectedMethod?.accountIdentifier) {
      return setIsEncryptionActive(true);
    }
    if (isWorldpay && !selectedMethod?.accountIdentifier) {
      return setIsEncryptionActive(true);
    }
    handleOnSubmit();
  }, [
    handleOnSubmit,
    handleServiceModeErrors,
    isOrbital,
    isWorldpay,
    selectedMethod?.accountIdentifier,
    validateCreditCardForm,
  ]);

  const onEncryptionResult = useCallback(
    (data: IOrbitalEncryptionResult | IWorldpayEncryptionResult) => {
      setIsEncryptionActive(false);
      setEncryptionResult(data);
      handleOnSubmit(undefined, data);
    },
    [handleOnSubmit, setEncryptionResult]
  );

  // Handle encryption error
  const onEncryptionError = (error: IOrbitalEncryptionError | IWorldpayEncryptionError) => {
    setIsEncryptionActive(false);
    setCustomHeaderMessageId('pleaseConfirmYourCreditCardInformation');
    setHasEncryptionError(true);
    setIsCheckoutErrorsModalOpen(true);
    const errorMessage = 'error' in error ? error.error : error.message;
    logger.error({ error: new Error(errorMessage), message: errorMessage });
  };

  const isPriceRequested = !serverOrder || orderStatus === OrderStatus.PRICE_REQUESTED;

  useLogOnLongLoading({
    name: 'BUTTON - "Place secured order" on Payment Order',
    isLoading: isCommiting,
    additionalContext: {
      isProcessingPayment: isCommiting,
      isOrderPriceLoading,
      isCartTotalNegative,
      isPriceRequested,
      shouldSkipPriceOrder,
      isOffersLoading,
      orderStatus,
    },
  });

  return (
    <>
      <CartPageError />
      <StyledFullPageScreen
        testID={CART_PAYMENT_PAGE}
        heading={getServiceModeHeaderText(formatMessage)[serviceMode]}
        headerBottom={<LoyaltyRewardsBalance />}
        closeHref={routes.cart}
        closeIcon="back"
      >
        {isCheckoutErrorsModalOpen && (
          <CheckoutErrorsModal
            headerMessageId={customHeaderMessageId}
            checkoutErrors={errorMessages}
            closeModal={() => {
              setIsCheckoutErrorsModalOpen(false);
              setCustomHeaderMessageId(undefined);
              setHasEncryptionError(false);
            }}
          />
        )}
        {!isDeliveryOrder && (
          <>
            {restaurantImage ? (
              <StyledPicture
                image={restaurantImage}
                alt={store.name ?? 'Restaurant Name Missing'}
                // TODO: RN - Add testID to Picture props
                // testID="store-confirmation-modal-restaurant-image"
              />
            ) : (
              <MapContainer accessibilityLabel="MapContainer">
                <StaticMap
                  // TODO: RN - Add testID to StaticMap props
                  // testID="store-confirmation-modal-static-map"
                  initialZoomIOS={8000}
                  position={{
                    lat: store.latitude ?? 0,
                    lng: store.longitude ?? 0,
                  }}
                />
              </MapContainer>
            )}
          </>
        )}
        <CartDetailsContainer ref={sectionRef}>
          <CartDetailsWithDayAndTimeSelector />
        </CartDetailsContainer>
        <PaymentSection testID="cart-payment">
          <SectionHeading accessible accessibilityRole="header" testID={CART_SELECT_PAYMENT_METHOD}>
            {formatMessage({ id: 'selectingPaymentMethod' })}
          </SectionHeading>
          {/*TODO replace with auth.user && auth.user.fdCustomerId*/}
          <PaymentSelection>
            {showPaymentMethodSelection && (
              <PaymentMethod
                aria-expanded="true"
                methods={paymentMethods}
                selectedMethodId={checkoutPaymentMethodId}
                handleSelect={handlePaymentMethodSelected}
                isAddCardOpen={showAddPaymentMethod}
                openAddCardOnPress={setShowAddPaymentMethod}
                isAddGiftCardMethodSelected={isAddGiftCardMethodSelected}
                setIsAddGiftCardMethodSelected={setIsAddGiftCardMethodSelected}
              />
            )}

            {showAddPaymentMethod && (
              <CreditCardFormInputs
                ariaLabel="credit-card-form-inputs"
                isDelivery={isDeliveryOrder}
                onChange={onChange}
                paymentValues={paymentValues}
                errors={errors}
                withBorder={false}
                withPadding={false}
                supportedCardTypes={supportedCardTypes}
              />
            )}

            {/* Show PrepaidTransferBalance if the GC is selected  */}
            {showPrepaidTransferBalance && <PrepaidTransferBalance />}

            {showReloadPaymentMethods && !!paymentMethods.length && (
              <PrepaidMethodReload
                reloadAmount={reloadAmount}
                reloadPaymentValues={reloadPaymentValues}
                setReloadPaymentValues={setReloadPaymentValues}
                isDelivery={isDeliveryOrder}
              />
            )}
            {needReloadAgain && (
              <ErrorContainer>
                <ErrorMessage>
                  {formatMessage({ id: 'insufficientBalanceReloadAgain' })}
                </ErrorMessage>
              </ErrorContainer>
            )}
            {isCateringOrder && (
              <DisclaimerCatering testID="disclaimer-catering">
                {`*${formatMessage({ id: 'cateringOrdersDisclaimer' })}`}
              </DisclaimerCatering>
            )}
            <Totals
              serverOrder={serverOrder}
              serviceMode={serviceMode}
              isDisabled={isPlaceOrderButtonDisabled}
            />
            {!isOfferPaymentCardValid && (
              <DisclaimerCardOfferInvalid>
                {formatMessage({ id: 'invalidPaymentCardTypeWithOffer' })}
              </DisclaimerCardOfferInvalid>
            )}
            {!selectedMethod && nativePaymentDisclaimer && (
              <WarningContainer>
                <WarningMessage message={nativePaymentDisclaimer} />
              </WarningContainer>
            )}
            <CtaWrapper>
              {isCurbside && !isSignUpAfterCart && (
                <VehicleDescription
                  onChange={setVehicleDescription}
                  value={vehicleDescription}
                  hasError={vehicleDescriptionHasError}
                />
              )}
              <PlaceOrderButton
                confirmation={!confirmStoreOnCheckout || isDeliveryOrder}
                isLoading={isPlaceOrderButtonLoading}
                disabled={isPlaceOrderButtonDisabled}
                placeOrderAriaLabel={placeOrderAriaLabel}
                onPress={handleOnPress}
              />
            </CtaWrapper>
            {isDeliveryOrder && enableDelivery && <DeliveryCancellationPolicy />}
          </PaymentSelection>

          <ErrorDialog />
          <CommitErrorDialog />
          <ServiceModeUnavailableErrorDialog testID="servicemode-unavailable-error-modal" />

          {isOrbital && isEncryptionActive ? (
            <SafetechEncryption
              cardNumber={paymentValues.cardNumber}
              cvv={paymentValues.cvv || ''}
              onResult={onEncryptionResult}
              onError={onEncryptionError}
            />
          ) : null}

          {isWorldpay && isEncryptionActive ? (
            <EProtectEncryption
              cardNumber={paymentValues.cardNumber}
              cvv={paymentValues.cvv || ''}
              onResult={onEncryptionResult}
              onError={onEncryptionError}
            />
          ) : null}
        </PaymentSection>
      </StyledFullPageScreen>
      {/* TODO: expo-router - Find if we still need this */}
      {/* <PaymentRoutes handlePaymentMethodSelected={handlePaymentMethodSelected} /> */}
    </>
  );
};
