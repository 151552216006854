import { Href, Stack, useRouter } from 'expo-router';

import { GenericErrorScreen } from '@fhs/cart';
import { useMqSelect } from '@fhs/ui';
import { useRumPageView } from '@fhs-legacy/frontend/src/hooks/rum/use-rum-page-view';
import { CartProvider } from '@fhs-legacy/frontend/src/state/cart';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';
import { PaymentProvider } from '@fhs-legacy/frontend/src/state/payments';

import { CheckoutHeader } from '../../components/app-header/cart-header';

function CheckoutHeaderWrapper() {
  const router = useRouter();
  const href = useMqSelect<Href>(
    { $gteDesktop: { pathname: router.canGoBack() ? '../' : '/menu', params: { drawer: 'cart' } } },
    '/cart'
  );
  return <CheckoutHeader href={href} title="Checkout" />;
}

const CartStack = () => {
  const router = useRouter();
  return (
    <Stack>
      <Stack.Screen
        name="index"
        options={{
          header: () => <CheckoutHeader href={router.canGoBack() ? '../' : '/menu'} title="Cart" />,
        }}
      />
      <Stack.Screen
        name="checkout"
        options={{
          header: () => <CheckoutHeaderWrapper />,
        }}
      />
      <Stack.Screen
        name="payment/index"
        options={{
          header: () => <CheckoutHeader href={router.canGoBack() ? '../' : '/'} title="Checkout" />,
        }}
      />
    </Stack>
  );
};

const CartPage = () => {
  useRumPageView('cart', 'Cart');

  const enableCartService = useFlag(LaunchDarklyFlag.ENABLE_SIMPLY_BETTER_CART_SERVICE);

  return enableCartService ? (
    <GenericErrorScreen>
      <CartStack />
    </GenericErrorScreen>
  ) : (
    <CartProvider>
      <PaymentProvider>
        <CartStack />
      </PaymentProvider>
    </CartProvider>
  );
};

export default CartPage;

export const unstable_settings = {
  initialRouteName: 'index',
};
